<template>
    <div>
        <a-form-model layout="inline" @submit.native.prevent>
            <a-form-model-item label="">
                <a-input v-model="formInline.inter_code" placeholder="请输入样品编号" class="w200" @keyup="Get_source_recordpage"></a-input>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-input v-model="formInline.item_name" placeholder="请输入检验项目" class="w200" @keyup="Get_source_recordpage"></a-input>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-input v-model="formInline.method_name" placeholder="请输入检验方法" class="w200" @keyup="Get_source_recordpage"></a-input>
            </a-form-model-item>

            <a-form-model-item label="状态">
                <a-select v-model="formInline.state" placeholder="请选择" class="w200" @change="Get_source_recordpage">
                    <a-select-option value="">全部状态</a-select-option>
                    <a-select-option value="3">待审核</a-select-option>
                    <a-select-option value="5">审核完成</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="时间">
                <a-date-picker v-model="formInline.starttime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                至
                <a-date-picker v-model="formInline.endtime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-button type="primary" @click="Search()">搜索</a-button>
                <a-button type="danger" @click="Clear()" class="ml10">清空搜索</a-button>
            </a-form-model-item>
            <!--            <a-form-model-item label="" style="float: right">-->
            <!--                <a-button type="primary" @click="Allsetsourcerecord()">审核通过</a-button>-->
            <!--                <a-button type="danger" class="ml10" @click="showDrawer">审核不通过</a-button>-->
            <!--            </a-form-model-item>-->
        </a-form-model>

        <a-space style="margin-top: 10px;" class="btner">
            <div class="btnerlist " @click="Allsetsourcerecord()">
                <i class="iconfont icon-tongguo1 vm" style="font-size: 16px"></i> 审核通过
            </div>
            <div class="btnerlist" @click="showDrawer">
                <i class="iconfont icon-shenhebutongguo1" style="font-size: 18px"></i> 审核不通过
            </div>
            <div class="btnerlist" @click="showDrawer1">
                <i class="iconfont icon-rizhi" style="font-size: 16px"></i> 操作日志
            </div>
        </a-space>

        <table class="layui-table" lay-size="sm" style="margin-top: -9px">
            <thead>
            <tr>
                <th>
                    <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                </th>
                <th @click="paixuclickup(1)" style="cursor: pointer; ">
                    <span>样品编号</span>
                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 1 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 1 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                    </div>
                </th>
                <th @click="paixuclickup(2)" style="cursor: pointer; ">
                    <span>检验项目</span>
                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                    </div>
                </th>
                <th @click="paixuclickup(3)" style="cursor: pointer; ">
                    <span>检验方法</span>
                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                    </div>
                </th>
                <th>检验结果</th>
                <th>标准值</th>
                <th>状态</th>
                <th>校验退回</th>
                <th>审核退回</th>
                <th>审核人</th>
                <th>审核首次完成时间</th>
                <th>结果最后修改时间</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in Sourcerecorddata" :key="key" :class="[item.check ? 'active':'']" @click="clickrow(key)">
                <td>
                    <a-checkbox v-model='item.check' @change="itemActiveChange"></a-checkbox>
                </td>
                <td>{{item.inter_code}}</td>
                <!--                <td @click="showModal1(item)" style="color: #1890ff; cursor: pointer">{{item.item_name}}</td>-->
                <td @click="urlJump(item.pdf_url)"><a target='_blank'>{{item.item_name}}</a></td>
                <td>{{item.method_name}}</td>
                <td> {{item.jc_result}}</td>
                <td>{{item.bz_value_str}}</td>
                <td style="cursor: pointer">
                    <span v-if="item.check_num>0 || item.auditor_num>0" style="color: #ff2929;">{{item.state_turn}}</span>
                    <span v-else>{{item.state_turn}}</span><br>
                    <span style="color: #ff2929;" v-if="item.state===4 ||item.state===6">( 原因：{{item.remark}})</span>
                </td>
                <td>{{item.check_num}} <span v-if="item.check_num===null">0</span> 次</td>
                <td>{{item.auditor_num}}<span v-if="item.auditor_num===null">0</span> 次</td>
                <td>{{item.auditor_name}}</td>
                <td>{{item.create_time}}</td>
                <td>
                    <span v-if="item.update_time===''">{{item.create_time}}</span>
                    <span v-else>{{item.update_time}}</span></td>
            </tr>
            </tbody>
        </table>
        <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>

        <!--单个检验不通过弹窗-->
        <div>
            <a-modal v-model="visible" title="请输入不通过原因" @ok="handleOk()">
                <div>
                    <a-textarea placeholder="请输入不通过原因" v-model="jiaoyanno.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
                </div>
            </a-modal>
        </div>
        <!--项目详情弹窗-->
        <div>
            <a-modal v-model="visible1" title="详情" @ok="handleOk1" width="80%">
                <div>
                    <div style="display: flex; justify-content:space-between">
                        <div style="font-size: 14px; font-weight: bold">{{xq_item.item_name}}</div>
                        <div>
                            <a-button type="primary" v-if="xq_item.state===2">审核通过</a-button>
                            <a-button type="danger" class="ml10" v-if="xq_item.state===2">审核不通过</a-button>
                        </div>
                    </div>
                    <div class="baogaomain">
                        <div v-if="xq_item.pdf_url==''" class="nodata">暂无文件</div>
                        <iframe :src="xq_item.pdf_url" frameborder="0" style="width: 100%; height: 600px"></iframe>
                    </div>
                </div>
            </a-modal>
        </div>
        <!--检验不通过弹窗-->
        <div v-if="selectdata">
            <NOdrawer :edit_time="edit_time" :selectdata="selectdata" :close="onCloseDrawer" :visible="drawer_visible"></NOdrawer>
        </div>
        <!--历史弹窗-->
        <div v-if="edit_info1">
            <Historylist :edit_time="edit_time1" :edit_info="edit_info1" :close="onCloseDrawer1" :visible="drawer_visible1"></Historylist>
        </div>
    </div>
</template>
<script>
    import NOdrawer from './nodrawer/nodrawer.vue';
    import Historylist from './lishilist/lishilist.vue';

    export default {
        name: "Checklist",
        components: {
            NOdrawer, Historylist
        },
        data() {
            return {
                xq_item: false,
                nor_item: false,
                selectdata: false,
                drawer_visible: false,
                drawer_visible1: false,
                formInline: {
                    inter_code: '',
                    item_name: '',
                    method_name: '',
                    state: '',
                },
                methodtypes: [],
                visible: false,
                visible1: false,
                value: '',
                Sourcerecorddata: [],
                jiaoyanno: {
                    remark: ''
                },
                edit_time1: 0,
                edit_info1: false,
                edit_time: 0,
                edit_info: false,
                all_check: false,
                activedata: '',
                page: 1,
                count: 0,
                pagesize: 0,
                active_key: -1,
                page_show: false,
                data_name: 0,
                data_sort: 'asc',
            }
        },
        mounted() {
            this.mountedDo()
        },

        watch: {
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
            itemActiveChange() {
                let do_ = true
                this.Sourcerecorddata.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Sourcerecorddata.map((item, key) => {
                    this.$set(this.Sourcerecorddata[key], 'check', this.all_check)
                })
            },
        },
        methods: {
            urlJump(pdf_url) {
                if (pdf_url === "") {
                    layer.msg("还没有检测，请检测后查看报告")
                } else
                    window.open(pdf_url,)
            },


            mountedDo() {
                this.Method_category_alllist()
                this.formInline.inter_code = this.$route.query.inter_code || '';
                this.formInline.item_name = this.$route.query.item_name || '';
                this.formInline.method_name = this.$route.query.method_name || '';
                this.formInline.state = this.$route.query.state || '';
                this.Get_source_record()
                setTimeout(() => {
                    this.page_show = true
                }, 1)
            },
            searchClick() {
                this.$router.push({
                    query: {
                        inter_code: this.formInline.inter_code,
                        item_name: this.formInline.item_name,
                        method_name: this.formInline.method_name,
                        state: this.formInline.state,
                    }
                })
            },

            clickrow(key) {
                this.active_key = key;
                this.$set(this.Sourcerecorddata[key], 'check', !this.Sourcerecorddata[key].check)
                this.itemActiveChange()
            },

            itemActiveChange() {
                let do_ = true
                this.Sourcerecorddata.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },

            allChange(e) {
                this.Sourcerecorddata.map((item, key) => {
                    this.$set(this.Sourcerecorddata[key], 'check', this.all_check)
                })
            },


            Search() {
                this.Get_source_record();
            },

            // 批量设置校验通过
            Allsetsourcerecord() {
                let ids = []
                this.Sourcerecorddata.map((item) => {
                    console.log(item)
                    if (item.check) {
                        ids.push({
                            'source_record_id': item.id,
                            'remark': '',
                        })
                    }
                })

                if (ids.length < 1) {
                    layer.msg('请勾选需要操作的样品')
                    return
                }
                this.$sa0.post({
                    url: this.$api('Set_source_record_all1'),
                    data: {
                        state: 5,
                        remark: '',
                        type: 2,
                        source_record_content: JSON.stringify(ids),
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_source_record()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            },


            // 校验通过
            Setsourcerecordok(item) {
                this.$sa0.post({
                    url: this.$api('Set_source_record1'),
                    data: {
                        source_record_id: item.id,
                        state: 5,
                        remark: '',
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg("设置成功")
                            this.Get_source_record()

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // zq_clickok() {
            //     this.$sa0.post({
            //         url: this.$api('Set_source_record'),
            //         data: {
            //             source_record_id: this.xq_item.id,
            //             state: 3,
            //             remark: '',
            //             type: 1
            //         },
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 layer.msg("设置成功")
            //                 this.Get_source_record()
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },


            Get_source_recordpage() {
                this.page = 1
                this.Get_source_record()
            },

            // 获取列表
            Get_source_record() {
                this.$sa0.post({
                    url: this.$api('Get_source_record1'),
                    data: {
                        page: this.page,
                        type: 2,
                        inter_code: this.formInline.inter_code,
                        item_name: this.formInline.item_name,
                        method_name: this.formInline.method_name,
                        state: this.formInline.state,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Sourcerecorddata = response.data.result.list
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            paixuclickup(ordertype) {
                if (this.data_name !== ordertype) {
                    this.data_name = ordertype
                    this.data_sort = 'asc'
                } else {
                    if (this.data_sort === 'asc') {
                        this.data_sort = 'desc'
                    } else {
                        this.data_sort = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('Get_source_record1'),
                    data: {
                        page: this.page,
                        type: 2,
                        inter_code: this.formInline.inter_code,
                        item_name: this.formInline.item_name,
                        method_name: this.formInline.method_name,
                        state: this.formInline.state,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                        data_name: this.data_name,
                        data_sort: this.data_sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Sourcerecorddata = response.data.result.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            onChange(page) {
                this.page = page;
                this.Get_source_recordpage() //获取列表记录
            },

            // 清空搜索
            Clear() {
                this.formInline.inter_code = '';
                this.formInline.item_name = '';
                this.formInline.method_name = '';
                this.formInline.state = '';
                this.formInline.starttime = '';
                this.formInline.endtime = '';
                this.Get_source_record();
            },


            Method_category_alllist() {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.methodtypes = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 单个不通过弹窗
            showModal(item) {
                this.visible = true;
                this.nor_item = item
            },
            handleOk() {
                console.log(this.nor_item)
                this.visible = false;
                this.$sa0.post({
                    url: this.$api('Set_source_record1'),
                    data: {
                        source_record_id: this.nor_item.id,
                        state: 6,
                        remark: this.jiaoyanno.remark,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg("设置成功")
                            this.Get_source_record()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 详情
            showModal1(item) {
                this.visible1 = true;
                this.xq_item = item
            },
            handleOk1() {
                console.log(123, this.xq_item)
                this.visible1 = false;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_source_record()
            },
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                let selectdata = []
                this.Sourcerecorddata.map((item) => {
                    console.log(item)
                    if (item.check) {
                        selectdata.push(item)
                    }
                })

                if (selectdata.length < 1) {
                    layer.msg('请勾选需要操作的样品')
                    return
                }
                this.selectdata = false
                setTimeout(() => {
                    this.edit_time = new Date() / 1;
                    this.selectdata = selectdata;
                    this.drawer_visible = true;
                })
                console.log(11111, selectdata)

            },
            // 日志定义 关闭抽屉时的 函数
            onCloseDrawer1() {
                this.drawer_visible1 = false;
                this.Get_source_record()
            },
            // 日志定义 打开抽屉时的 函数
            showDrawer1(item) {
                let id = 0
                let count = 0
                this.Sourcerecorddata.map((item, key) => {
                    if (item.check) {
                        this.edit_info1 = item;
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择样品')
                if (count !== 1) return layer.msg('只能选择一个样品')
                console.log(987, this.edit_info1)
                setTimeout(() => {
                    this.edit_time1 = new Date() / 1;
                    // this.edit_info1 = item;
                    this.drawer_visible1 = true;
                })
            }
        }
    }
</script>

<style scoped>
    .w100{ width: 100px}

    .w200{ width: 200px}

    .ml10{ margin-left: 10px}

    .baogaomain{ width: 100%; background: #f9f9f9; margin-top: 20px; overflow-y: auto}

    .nodata{ font-size: 20px; text-align: center; padding-top: 50px; font-weight: bold; }


    .btner{
        width: 100%;
        background: #f9f9f9;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        margin-bottom: 10px;
        }

    .active{
        background: #ebf6ff;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #eeeeee;
        height: 26px;
        line-height: 26px;
        padding: 0 10px
        }

    .btnerlist :last-child(1){
        border: none
        }

    .fcadd{
        color: #66c14c !important;
        font-size: 18px !important;
        }

    .btnerlist i{
        color: #999999;
        font-size: 14px;
        vertical-align: bottom;
        }

    .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;

        }

    .btnerlist:hover i, .btnerlist:hover.fcadd{
        color: #ffffff !important
        }

    .isthis{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        }

    .isthis i{
        color: #ffffff
        }

    table tr{ cursor: pointer}

    .active{ background: #eff8ff}

    .ant-table-column-sorter{
        display: inline-flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 5px;
        cursor: pointer;
        width: 10px;
        vertical-align: middle;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .active{ background: #ebf6ff}

    table tr{ cursor: pointer}


</style>
