<script>
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_time', 'selectdata'],
        data() {
            return {
                selecttype: 1,
                value: '',
                selectdata1: [],
                remark: '',
                allremark: {
                    remark: '',
                }
            }
        },
        watch: {
            edit_time() {
                this.selectdata1 = JSON.parse(JSON.stringify(this.selectdata))
            },
        },
        mounted() {
            this.selectdata1 = JSON.parse(JSON.stringify(this.selectdata))
            console.log(3333, this.selectdata1)
        },
        methods: {
            DoClick() {
                let ids = []
                this.selectdata1.map((item) => {
                    console.log(item)
                    if (item.check) {
                        ids.push({
                            'source_record_id': item.id,
                            'remark': item.remark,
                        })
                    }
                })
                this.$sa0.post({
                    url: this.$api('Set_source_record_all1'),
                    data: {
                        source_record_content: JSON.stringify(ids),
                        state: 6,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.close();
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            DoClick1() {
                let ids = []
                this.selectdata1.map((item) => {
                    console.log(item)
                    if (item.check) {
                        ids.push({
                            'source_record_id': item.id,
                            'remark': this.allremark.remark,
                        })
                    }
                })
                this.$sa0.post({
                    url: this.$api('Set_source_record_all1'),
                    data: {
                        source_record_content: JSON.stringify(ids),
                        state: 6,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.close();
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },


            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="审核不通过原因" width="95%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <div class="titlebg">
                    <a-radio-group name="radioGroup" :default-value="1" v-model="selecttype">
                        <a-radio :value="1">
                            单个不通过
                        </a-radio>
                        <a-radio :value="2">
                            批量不通过
                        </a-radio>
                    </a-radio-group>
                </div>

                <div v-if="selecttype==1">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>样品编号</th>
                            <th>检验项目</th>
                            <th>检验方法</th>
                            <th>检验结果</th>
                            <th>标准值</th>
                            <th>历史校验操作</th>
                            <th>不通过原因</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in selectdata1" :key="key">
                            <td>{{item.inter_code}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.method_name}}</td>
                            <td>{{item.bz_value}}</td>
                            <td>{{item.bz_value}}</td>
                            <td>
                                <div v-for="(item,key) in item.source_record_log"> {{item}}</div>
                            </td>
                            <td>
                                <a-textarea v-model="item.remark" placeholder="请输入不通过原因" :auto-size="{ minRows: 1, maxRows: 5 }"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <a-button type="primary" @click="DoClick()">确定审核不通过</a-button>
                </div>

                <div v-if="selecttype==2">

                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>样品编号</th>
                            <th>检验项目</th>
                            <th>检验方法</th>
                            <th>检验结果</th>
                            <th>标准值</th>
                            <th>历史校验操作</th>
                            <th>不通过原因</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in selectdata1" :key="key">
                            <td>{{item.inter_code}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.method_name}}</td>
                            <td>{{item.bz_value}}</td>
                            <td>{{item.bz_value}}</td>
                            <td>
                                <div v-for="(item,key) in item.source_record_log"> {{item}}</div>
                            </td>
                            <td :rowspan="selectdata1.length" v-if="key===0">
                                <a-textarea v-model="allremark.remark" placeholder="请输入不通过原因" :auto-size="{ minRows: 1, maxRows: 5 }"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <a-button type="primary" @click="DoClick1">确定审核不通过</a-button>
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .titlebg{ width: 100%; background: #f9f9f9; padding: 10px; border-radius: 4px }

</style>
